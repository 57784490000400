<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import gmapsInit from '@/gmaps';
import http from '@/app-http'
import { mapGetters } from 'vuex'
import StatusColor from '@/views/pages/directories/status-color.vue';
import moment from 'moment-timezone';

export default {
  
  page: {
    title: "Heat map",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  async mounted () {
    window.addEventListener("resize", this.debCalculateHeight);
    this.debCalculateHeight();

    const google = await gmapsInit();
    this.google = google;

    if (!this.center && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.initMap({lat: position.coords.latitude, lng: position.coords.longitude})
      }, () => this.initMap(this.center), {timeout:10000});
    } else {
      this.initMap(this.center)
    }    
  },
  beforeUnmount() {
   
  },
  unmounted() {
    window.removeEventListener("resize", this.debCalculateHeight);
    if(this.map)
      this.google.maps.event.clearListeners(this.map);
  },
  components: {
    Layout,
    StatusColor
  },
  data() {
    return {
      pagetitle: "Теплова мапа",
      statusList: [],
      from: null,
      till: null,
      loading: false
    };
  },
  created(){
   
  },
  computed :{
    ...mapGetters('auth', {
      token: 'token'
    }),
    ...mapGetters('directories', {
      directories: 'items'
    }),
    requestEntity() {
      return this.directories.find(x => x.id === '1ee317c47fb34cb3888f0788ccf19d0b');
    }
  },
  methods: {
    // debLoad: debounce(function(){
    //   this.load();
    // }, 600),
    initMap(center) {
      if(this.map)
        return;
      if(!center) {
        center = { lat: (this.address ||{}).lat || 50.4501, lng: (this.address || {}).lng || 30.5234 };
      }
      let map = new this.google.maps.Map(this.$refs.map, {
        center: center,
        zoom: 8,
      });
    
      this.map = map;

      this.infowindow = new this.google.maps.InfoWindow({
        content: '',
      });

      // this.google.maps.event.addListener(this.infowindow, 'domready', () => {
      //     console.log('a[domready]');

      //   if(this.infowindow.marker) {
      //     document.getElementById('gmap_infowindow_header_a').addEventListener("click", () => {
      //       this.onShowForm(this.infowindow.marker.mapObj, this.infowindow.marker.model);
      //     });
      //     const hrefs = document.querySelectorAll('a[data-item]');
      //     hrefs.forEach(a => {
      //       a.addEventListener("click", () => {
      //         const itemId = a.getAttribute('data-item');
      //         const defId = a.getAttribute('data-item-def');
      //         // const directory = this.directories.find(x => x.id === defId);
      //         // console.log('a[data-item]', a);
      //         this.openItem({ item: { id: itemId, directoryId: defId}, mode: 'view'});
      //       });
      //     })
      //   }
      // });

      //this.google.maps.event.addListener(this.map, 'bounds_changed', this.debLoad);
      this.calculateHeight();
      //this.load();
    },
    debCalculateHeight() {
      this.$nextTick(()=> this.calculateHeight());
      window.setTimeout(() => {
         this.calculateHeight()
      }, 1000);
    },
    calculateHeight() {
      const table = document.getElementById('map') || this.$refs['map'];
      const windowHeight = window.innerHeight;
      const toolbar = document.getElementById("user-toolbar");
      const topBar = document.getElementById("page-topbar");

      if(table && toolbar) {
        const o = topBar.offsetHeight + toolbar.offsetHeight 
        + 40 //paddings
        ;
        const boardMaxHeight = windowHeight - o; // topbar + paddings

        table.style.maxHeight = boardMaxHeight + 'px';  
        table.style.height = boardMaxHeight + 'px';  
      } else if(table) {
        const o = topBar.offsetHeight
        + 40 //paddings
        ;
        const boardMaxHeight = windowHeight - o; // topbar + paddings

        table.style.maxHeight = boardMaxHeight + 'px';  
        table.style.height = boardMaxHeight + 'px';  
      }
    },
    onZoomChange() {
      var zoomLevel = this.map.getZoom();
      const radius = this.getRadiusByZoomLevel(zoomLevel);
      this.heatmap.set('radius', radius);
    },
    getRadiusByZoomLevel(zoomLevel) {

      if(zoomLevel <= 9) {
        return 10;
      } else  if(zoomLevel <= 10) {
        return 15;
      } else  if(zoomLevel <= 11) {
        return 20;
      } else  if(zoomLevel <= 11) {
        return 25;
      }
      return 30;

    } ,
    initHeatmap(heatmapData) {
      console.log('initHeatmap' );
      if(this.heatmap) {
        this.heatmap.setMap(null);
      }
      //const heatmapData = this.generateHeatmapData();
      const heatmap = new this.google.maps.visualization.HeatmapLayer({
        data: heatmapData,
        gradient: [
          'rgba(0, 255, 255, 0)',
          'rgba(0, 255, 255, 1)',
          'rgba(0, 191, 255, 1)',
          'rgba(0, 127, 255, 1)',
          'rgba(0, 63, 255, 1)',
          'rgba(0, 0, 255, 1)',
          'rgba(0, 0, 223, 1)',
          'rgba(0, 0, 191, 1)',
          'rgba(0, 0, 159, 1)',
          'rgba(0, 0, 127, 1)',
          'rgba(63, 0, 91, 1)',
          'rgba(127, 0, 63, 1)',
          'rgba(191, 0, 31, 1)',
          'rgba(255, 0, 0, 1)'
        ],
        //dissipating: false,
        radius: this.getRadiusByZoomLevel(this.map.getZoom()) * 5
      });
      heatmap.setMap(this.map);
      this.heatmap = heatmap;
    },
    toggleStatus(status) {
      if(this.statusList.find(x => x.id === status.id)) {
        this.statusList = this.statusList.filter(x => x.id !== status.id);
      } else {
        this.statusList = [...this.statusList, status];
      }
    },
    async load() {
      this.loading = true;
      let heatmapData = [];
      const url = '/directories/1ee317c47fb34cb3888f0788ccf19d0b/items/search';
      const conditions =[];
      if(this.from) {
        conditions.push(
          {
            "op": "GreaterOrEqual",
            "lgOp": "And",
            "value": {
              "stringValue": "",
              "dateValue":  moment(this.from).format("YYYY-MM-DD"),
              "numberValue": null,
              "statusList": [],
              "dirValue": null
            },
            "id": "a467c550-1d40-11ed-afec-7918b6c60307",
            "directory": "1ee317c47fb34cb3888f0788ccf19d0b",
            "field": "a467c550-1d40-11ed-afec-7918b6c60307"
          }
        );
      }

      if(this.till) {
        conditions.push(
          {
            "op": "LessOrEqual",
            "lgOp": "And",
            "value": {
              "stringValue": "",
              "dateValue": moment(this.till).format("YYYY-MM-DD"),
              "numberValue": null,
              "statusList": [],
              "dirValue": null
            },
            "id": "a467c550-1d40-11ed-afec-7918b6c60307",
            "directory": "1ee317c47fb34cb3888f0788ccf19d0b",
            "field": "a467c550-1d40-11ed-afec-7918b6c60307"
          }
        );
      }
    
      const response = await http.post(url, {
        take: 100000,
        skip:0,
        orderBy: {
          field: 'Number',
          asc: true
        },
        statusList: this.statusList.length === 0 ? null :this.statusList.map(x => x.id),
        conditions: conditions.length === 0 ? null : conditions
      });

      let noaddress = [];
      let nosum = [];
      let rawData = [];
      response.data.items.forEach(x => {
        const addressTable = x.customFields['b194d1f0-3da2-11ed-9bc5-912acef1cd1a'];
        if(addressTable?.length > 0) {
          let sum = 0 ;
          const countOfHelpTable = x.customFields['ea7033f0-14c8-11ed-a7a3-6fb9223447c4'];
          if(countOfHelpTable?.length > 0) {
          
            countOfHelpTable.forEach(h => {
              try {
                const v = h['bf144e00-6b2e-11ed-9431-1d3c95e9d6b4'];
                if(v) {
                  const s = parseFloat(v);
                  sum += s;
                }
              }catch(e){
                /* tslint:disable:no-empty */ 
              }
            });
          }

          if(sum === 0) {
           
            const stockReqTable = x.customFields['3c6c12e0-f5ee-11ec-ba39-9dbd873ea88a'];
            if(stockReqTable?.length > 0) {
              stockReqTable.forEach(h => {
                try {
                  const v = h['27146a50-0444-11ed-9187-8129daef35f0'];
                  if(v) {
                    const s = parseFloat(v);
                    sum += s;
                  }
                }catch(e) {
                  /* tslint:disable:no-empty */ 
                }
              });
            }
          }

          if(sum > 0) {
           
            const addressList = [];
            addressTable.forEach(a => {
              const address = a['92a86860-3da2-11ed-9bc5-912acef1cd1a'];
              if(address) {
                addressList.push({location: new this.google.maps.LatLng(address.lat,address.lng), weight: 1, raw: address});
              }
            });
            const sumPerAddress = sum / addressList.length;
            const weight = Math.log(sumPerAddress);
           
            addressList.forEach(a => {
              heatmapData.push({...a, weight});
              rawData.push({address: a.raw, weight, x, sumPerAddress, sum});
            });
          } else {
            nosum.push({title: x.display, number: x.number, id: x.id});
          }
         
        } else {
          noaddress.push({title: x.display, number: x.number, id: x.id});
        }
      });

      if(noaddress.length > 0) {
        console.log('noaddress', noaddress);
      }
      if(nosum.length > 0) {
        console.log('nosum', nosum);
      }
      this.rawData = rawData;
      //console.log(response);
      this.initHeatmap(heatmapData);
      this.loading = false;
    },
    arrayToCSV(data) {
        // Предполагается, что 'data' это массив объектов
        const csvRows = [];
        // Получаем заголовки
        const headers = Object.keys(data[0]);
        csvRows.push(headers.join(','));

        // Проходим по строкам и формируем данные
        for (const row of data) {
            const values = headers.map(header => {
                const escaped = (''+row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(','));
        }

        return csvRows.join('\n');
    },
    downloadCSV(csvContent, fileName) {
        // UTF-8 BOM
      const BOM = "\uFEFF";
      // Создаем ссылку на объект Blob, содержащий данные в формате CSV с BOM
      const blob = new Blob([BOM + csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);

      // Создаем временную ссылку и имитируем клик по ней для начала загрузки
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.setAttribute('download', fileName);
      downloadLink.style.display = 'none';

      // Добавляем ссылку в DOM
      document.body.appendChild(downloadLink);

      // Имитируем клик по ссылке
      downloadLink.click();

      // Удаляем ссылку из DOM
      document.body.removeChild(downloadLink);
    },
    downdlowRawData() {
      const csv = this.rawData.map(x =>({
        region: x.address.region,
        city: x.address.city,
        sumPerAddress: Math.round(x.sumPerAddress, 2),
        weight: Math.round(x.weight),
        number: '#' + x.x.number.toString(),
        display: x.x.display || '',
        id: x.x.id,
       
      }));
      //console.log('downdlowRawData', csv);
      const csvData = this.arrayToCSV(csv);
      this.downloadCSV(csvData, 'heatmap.csv');
    }
  }
};
</script>

<template>
  <Layout :pagetitle="pagetitle">
    <div class="row"  ref="toolbar" id="user-toolbar">
      <div class="row g-3 align-items-center">
        
        <div class=" col-auto" v-if="statusList && requestEntity">
          <small><label class="m-0">Статус</label></small>
        
          <div class="dropdown ">
            <button
              :title="statusList.length > 0  ? statusList.map(x => x.name).join(', ') : ''"
              type="button"
              class="btn bg-white waves-effect waves-light text-nowrap text-truncate"
          
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              data-bs-auto-close="outside"
              style="position:relative; padding-right: 30px; min-width: 230px; max-width: 300px; border: 1px solid #e2e5e8 !important; text-align: left;"
            >
              <span v-if="statusList.length === 0 || statusList.length === requestEntity.statusList.length">{{$t('common.allStatuses')}}</span>
              <template v-if="statusList.length > 0 && statusList.length < requestEntity.statusList.length">
                <span class=" me-3 text-nowrap text-truncate" v-for="st in statusList" :key="st.id">
                  <status-color :color="st.color"></status-color>{{st.name}}
                </span>
              </template>

              <span style="position: absolute; right:5px; top:10px">
                <font-awesome-icon icon="fa-solid fa-caret-down" class=""/>
              </span>
            
            </button>
            <div class="dropdown-menu dropdown-menu-end pt-2">
              <template v-for="status in requestEntity.statusList || []" :key="status.id">
                <a 
                  class="dropdown-item text-wrap"  
                  href="javascript:void(0)" 
                  :class="{'active': statusList.find(a => a.id === status.id)}" 
                  @click="toggleStatus(status)"
                >
                  <font-awesome-icon icon="fa-regular fa-square-check" v-if="statusList.find(a => a.id === status.id)"/>
                  <font-awesome-icon icon="fa-regular fa-square"  class="text-muted" v-if="!statusList.find(a => a.id === status.id)" />
                  <span class="ms-2"> 
                    <status-color :color="status.color"></status-color>
                    {{status.name}}
                  </span>
                </a>
              </template>
            
            </div>
          </div>
        </div>
        <div class="col-auto">
          <small><label class="m-0">Дата (от)</label></small>
          <input 
            type="date" 
            class="form-control " 
            :placeholder="$t('common.from')"
            v-model="from"
          />
        </div>
        <div class="col-auto">
          <small><label class="m-0">Дата (до)</label></small>
          <input 
            type="date" 
            class="form-control " 
            :placeholder="$t('common.till')"
            v-model="till"
          />
        </div>
        
        <div class="col-auto">
          <small><label class="mt-4">&nbsp;</label></small>
          <button class="btn btn-primary mt-2" @click="load">
            Завантажити 
          </button>
        </div>
        <div class="col-auto">
          <small><label class="mt-4">&nbsp;</label></small>
          <button class="btn btn-secondary mt-2" @click="downdlowRawData">
            <i class="fa fa-download me-2"></i> 
            CSV File
          </button>
        </div>
      </div>
    </div>
    <!-- <filter-right-sidebar v-if="selectedDirectoryId && !initializing" :directory-id="selectedDirectoryId" :filter-key="filterKey" >
    </filter-right-sidebar>

    <div class="row"  ref="toolbar" id="user-toolbar">
      <div class="col-auto">
        <select class="form-control" v-if="mapDirectories.length > 0" v-model="selectedDirectoryId">
          <option v-for="dir in mapDirectories" :key="dir.id" :value="dir.id">{{dir.name}}</option>
        </select>
      </div>
      <div class="col-auto">
        <div class="d-none d-lg-block" style="position:relative;min-width:150px">
          <input class="form-control" :placeholder="$t('common.search')" style="padding-left:30px" v-model="keyword"/>
          <span class="text-muted" style="position:absolute; top:9px; left:10px" >
            <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
          </span>
        </div>
      </div>
      <div class="col-auto">
        <div class="d-none d-lg-block" style="position:relative;">
          <tags :tags="tags" :value="filterTags" @update="tags => filterTags = tags" :editable="true" :add-tag-text="$t('common.searchByTag')"></tags>
        </div>
      </div>
      <div class="col text-end">
        <div
          class="d-flex flex-wrap align-items-center justify-content-end "
        >
          <div>
          <input class="form-control" ref="autocompleteInput" style="min-width:250px"/>
          </div>
          <div class="ms-2">
            <div class="dropdown d-inline-block">
              <button
                type="button"
                class="btn btn-secondary"
              
                @click.stop.prevent="toggleFilter(slotProps)"
              >
                <i class="fa fa-filter "></i>
                <div class="text-sm-end d-none d-md-inline-block ms-2">
                  {{$t('common.filters')}}
                </div>
              </button>
            </div>
          </div> 
        
        </div>
      </div>
    </div> -->
    <div class="bg-white" style="margin-top:10px">
      <div class="m-0" style="position: relative;">
        <div
          style="position:relative;"
          ref="map"
          id="map"
        ></div>
        <div class="rightbar-overlay d-flex align-items-center justify-content-center" v-if="loading">
          <i class="fa fa-spin fa-spinner" style="font-size: 4rem; color: white;"></i>
        </div>
      </div>
    </div>
  </Layout>
</template>
